import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { DataServices } from '../services/data.services';
import { AuthService } from '../services/auth.service';
import { FilterModel } from '../model/filter.model';
import { environment } from '../../environments/environment';
import { forkJoin } from "rxjs/observable/forkJoin";
import { fromEvent, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { debounceTime, map } from 'rxjs/operators';


import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { from } from 'rxjs/observable/from';
import { delay } from 'rxjs/operators/delay';
import { switchMap } from 'rxjs/operators/switchMap';
import { tap } from 'rxjs/operators/tap';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../app.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild("list") list;
  public source: Array<{ name: string, code: string }> = [];

  public ddlData: Array<{ name: string, code: string }>;

  public downloadData: any;
  public totalRecords: number;
  public totalPages: number;
  public pageNo: number;
  public pageNumberString: string;
  public loadingMessage: string;
  initialSearchMessage: boolean = false;
  title = 'app';
  userRole: string;
  userName: string;
  linkUrl: string = environment.linksUrl;
  currentYear: number;
  columnWidth: string[];
  tableHeader: string[];
  data: any[];
  originalData: any[];

  blnEditSuppLeadTime: boolean =false;
  blnEditSBMLeadTime: boolean = false;
  blnEditBuyerAction: boolean = false;
  blnShowExtract: boolean = false;
  

  ddSupplierData: any[];
  ddSBMData: any[];
  ddTargetLTData: any[];
  ddDemandData: any[];
  ddActionData: any[];
  ddPlantData: any[];

  arrShowComments: boolean[];

  filterModel: FilterModel;



  modelMaterial: any;
  materialList = [];
  materialAutoCompleteStatus: string;
  
  @ViewChild('materialCodeElt')
  materialCodeElt: ElementRef;

  redirectionURL: string = environment.redirectionURL;

  ngAfterViewInit() {
    //const contains = value => s => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    //this.list.filterChange.asObservable().pipe(
    //  switchMap(value => from([this.source]).pipe(
    //    tap(() => this.list.loading = true),
    //    delay(1000),
    //    map((data) => data.filter(contains(value)).slice(0,100))
    //  ))
    //)
    //  .subscribe(x => {
    //    this.ddlData = x;
    //    this.list.loading = false;
    //  });
  }

  checkValidToken(token: string) {
    if (token) {
      if (token.split(".").length < 3) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  constructor(public dataServices: DataServices,
    private authService: AuthService) {
    this.ddlData = this.source.slice(0,100);

    this.loadingMessage = "";
    //this.blnShowExtract = false;
    //this.blnEditSuppLeadTime = true;
    //this.blnEditSBMLeadTime = true;
    //this.blnEditBuyerAction = true;


    this.txtQueryChanged
      .pipe(debounceTime(1000)) // wait 1 sec after the last event before emitting last event
      .subscribe(model => {
        this.supplierPopupFilter = model;
        this.filteredvalues = this.ddSupplierData.filter(t => t.name.toLowerCase().indexOf(this.supplierPopupFilter.toLowerCase()) != -1 || t.code.toLowerCase().indexOf(this.supplierPopupFilter.toLowerCase()) != -1);

        this.supplierPopupLoading = false;
        // Call your function which calls API or do anything you would like do after a lag of 1 sec
        
      });




    this.arrShowComments = new Array(50);
    this.filterModel = new FilterModel();
    this.data = [];
    this.originalData = [];
    const now = new Date();
    this.currentYear = now.getFullYear();
    this.getFilterData();

    this.tableHeader = [
      "LAM PART#", "DESCRIPTION", "MFR NAME", "MFR PART", "REV", "BUYR", "SBM", "SC", "DMD 1-13", "DMD 14-26", "SAP Lead Time", "TGT Lead Time", "Supp Lead Time", "SBM Lead Time", "Comments", "Prod Type", "Supplier", "Supplier Name", "Buyer Name", "SBM Name", "SUPP Date", "SBM Date", "Extract Date", "Plant"
    ];
    this.columnWidth = ["150px", "210px", "180px", "110px", "45px", "45px", "45px", "45px", "40px", "45px", "45px", "45px", "45px", "45px", "250px", "180px", "75px", "150px", "95px", "95px", "75px", "75px", "95px", "75px"]

    // var refreshIntervalId = setInterval(function () {
    //  // console.log("hello ...");
    // document.getElementById("tableHeader").scrollLeft = document.getElementById("tableMain").scrollLeft
    //}, 10);

    this.setHeader();

    //if (this.checkValidToken(this.getUrlParams("token"))) {
    //  //window.localStorage.setItem('token', this.getUrlParams("token"));
    //  let sessionIDforValidate;
    //  try {
    //    console.log("tryyyyyyyyyy");
    //    sessionIDforValidate = this.dataServices.getSessionID(this.getUrlParams("token"));
    //    console.log("ttttttttttt     ", sessionIDforValidate);
    //    this.dataServices.showApplication = true;
    //  } catch{
    //    console.log("catchhhhhhhhh      ", sessionIDforValidate);
    //    window.location.href = this.redirectionURL;
    //  }
    //} else {
    //  console.log("elsee for redirection for invalid token ... ")
    //  window.location.href = this.redirectionURL;
    //}

    console.log("token .... ")
    if (this.checkValidToken(this.getUrlParams("token"))) {
      var _token = this.getUrlParams("token");
      console.log("_token actual   ", _token);
      window.localStorage.setItem('token', _token);
      let sessionIDforValidate;
      try {        
        sessionIDforValidate = this.dataServices.getSessionID(_token);
        console.log("sessionIDforValidate   .....  tryyyy  ", sessionIDforValidate);
        this.dataServices.showApplication = true;
      } catch{
        console.log("catchhhhhh   .....    ", sessionIDforValidate);
        window.location.href = this.redirectionURL;
      }

      this.authService.setAuthorizationToken(_token);

      this.dataServices.user = this.dataServices.getUser();

      this.dataServices.isVendor = this.dataServices.getIsVendor();
      if (this.getUrlParams("suppliercode")) {
        
        this.dataServices.suppliercode = this.getUrlParams("suppliercode").toString();
        this.dataServices.vendorId = this.getUrlParams("suppliercode").toString();
        console.log("supplier code   ", this.dataServices.vendorId);
      }
      //this.dataServices.vendorId = this.dataServices.getVendorId();
      //this.dataServices.getLeadTimeUpdateRole().subscribe((res) => {
      //  console.log("res >>>     ", res);
      //  //this.dataServices.selectedSuppCode = res;
      //  this.dataServices.preloader = false;
      //})

      //this.dataServices.role = this.dataServices.getRole();

      //if (this.dataServices.role) {
      //  if (this.dataServices.role == "admin") {
      //    this.blnShowExtract = true;
      //    this.blnEditSuppLeadTime = false;
      //    this.blnEditSBMLeadTime = true;
      //    this.blnEditBuyerAction = true;
      //  } else if (this.dataServices.role == "vendor") {
      //    this.blnShowExtract = false;
      //    this.blnEditSuppLeadTime = true;
      //    this.blnEditSBMLeadTime = false;
      //    this.blnEditBuyerAction = false;
      //  } else if (this.dataServices.role == "buyer") {
      //    this.blnShowExtract = false;
      //    this.blnEditSuppLeadTime = false;
      //    this.blnEditSBMLeadTime = true;
      //    this.blnEditBuyerAction = true;
      //  }
      //}

      //if (this.dataServices.isVendor) {
      //  this.filterModel.supplierCode = this.dataServices.vendorId;
      //  this.onSearch();
      //}

      let plantDataCall = this.dataServices.getPlantData();
      let supplierDataCall = this.dataServices.getSupplierData();
      let sbmDataCall = this.dataServices.getSBMData();
      let getleadTimeUpdateRole = this.dataServices.getLeadTimeUpdateRole();

      this.dataServices.preloader = true;
      this.loadingMessage = "Initializing...";
      forkJoin([plantDataCall, supplierDataCall, sbmDataCall, getleadTimeUpdateRole]).subscribe(dt => {
        this.loadingMessage = "";
        console.log("========   getleadTimeUpdateRole      ", dt[3]);
        let _role = dt[3].toString();
        this.getLeadTimeUpdateRole(_role);
        this.ddPlantData = dt[0];
        this.ddSupplierData = dt[1];
        this.filteredvalues = dt[1];
        this.ddSBMData = dt[2]; 
        this.dataServices.preloader = false;
        //this.getLeadTimeUpdateRole();

        this.source = dt[1].map(obj => {
          var rObj = {};
          rObj["name"] = obj.code+'-'+obj.name;
          rObj["code"] = obj.code;
          return rObj;
        });
        this.source.unshift({ code: "ALL", name:"All" });
        this.ddlData = this.source.slice(0, 100);

        //console.log("========     ", this.ddPlantData[0].code );
        //this.filterModel.supplierCode = "ALL";
        this.filterModel.plant = this.ddPlantData[0].code;
        if (this.dataServices.isVendor) {
          console.log("supplier code  ===============  ", this.dataServices.vendorId);

          this.filterModel.supplierCode = this.dataServices.vendorId;
          this.onSearch();
        } else {
          this.filterModel.supplierCode = "ALL";
          this.initialSearchMessage = true;
        }
        

      });

      this.filterModel.sbm = "ALL";
      this.filterModel.targetLT = "ALL";
      this.filterModel.demand = "ALL";
      this.filterModel.action = "ALL";
      //this.filterModel.plant = "";
      this.filterModel.leadTime = "0";
    } else {
      //alert("Not a valid user.");
      console.log("else    .....    ");
      window.location.href = this.redirectionURL;
      //this.dataServices.preloader = false;
    }
  }
  isSupplierSearchPopupShow: boolean = false;
  supplierPopupFilter: string="";
  clickSupplierSearch() {
    this.isSupplierSearchPopupShow = true;
  }

  getLeadTimeUpdateRole(role: string) {
    console.log(">>>>>>>>>>> rolerolerolerole   ", role);
    //role = 'vendor'
    this.dataServices.role = role;
    if (role) {
      if (role == "admin") {
        this.blnShowExtract = true;
        this.blnEditSuppLeadTime = false;
        this.blnEditSBMLeadTime = true;
        this.blnEditBuyerAction = true;
      } else if (role == "vendor") {
        this.blnShowExtract = false;
        this.blnEditSuppLeadTime = true;
        this.blnEditSBMLeadTime = false;
        this.blnEditBuyerAction = false;
      } else if (role == "buyer") {
        this.blnShowExtract = false;
        this.blnEditSuppLeadTime = false;
        this.blnEditSBMLeadTime = true;
        this.blnEditBuyerAction = true;
      }
    }
    
  }
  cancelSupplierSearchPopup() {
    this.isSupplierSearchPopupShow = false;
  }

  selectSupplierFromPopup(data) {
    this.filterModel.supplierCode = data.code;
    this.isSupplierSearchPopupShow = false;
  }
  txtQueryChanged: Subject<string> = new Subject<string>();
  supplierPopupLoading: boolean = false;
  onSerachPopupChange(query) {
    console.log("change ...  ", query);
    this.supplierPopupLoading = true;
    //this.filteredvalues = this.ddSupplierData.filter(t => t.name.toLowerCase().indexOf(this.supplierPopupFilter.toLowerCase()) != -1 || t.code.toLowerCase().indexOf(this.supplierPopupFilter.toLowerCase()) != -1);
    this.txtQueryChanged.next(query);
  }
  

  filteredvalues: any;
  filteredvalue() {
      
  }
  onSupplierCodeChange(newValue) {
    //change local storage stored value
    //window.localStorage.setItem('selectedSuppCode', newValue);
    this.setVendorPersisitance(newValue);
    this.filterModel.supplierCode = newValue;
    //this.valueChange();
  }

  setVendorPersisitance(venCode: string) {
    this.dataServices.preloader = true;
    this.dataServices.manageVendorPersisitance(venCode, 'INSERT').subscribe((res) => {
      console.log("res >>>     ", res);
      //this.dataServices.selectedSuppCode = res;
      this.dataServices.preloader = false;
    })
  }

  lamMessagePage() {
    window.location.href = environment.lamMessage;
  }

  forecast3Page() {    
    if (!this.dataServices.getIsVendor()) {
      this.filterModel.supplierCode = "";
    }
    window.location.href = environment.forecast3 + "/forecast3?suppliercode=" + this.filterModel.supplierCode + "&token=" + this.dataServices.getTokenValue();
  }
  forecast4Page() {
    if (!this.dataServices.getIsVendor()) {
      this.filterModel.supplierCode = "";
    }
    window.location.href = environment.forecast4 + "/forecast4?suppliercode=" + this.filterModel.supplierCode + "&token=" + this.dataServices.getTokenValue();
  }

  NCPage() {
    if (!this.dataServices.getIsVendor()) {
      this.filterModel.supplierCode = "";
    }
    window.location.href = environment.nonConformances + "/home?suppliercode=" + this.filterModel.supplierCode + "&token=" + this.dataServices.getTokenValue();
  }

  firstArticlePage() {
    if (!this.dataServices.getIsVendor()) {
      this.filterModel.supplierCode = "";
    }
    window.location.href = environment.firstArticle + "/home?suppliercode=" + this.filterModel.supplierCode + "&token=" + this.dataServices.getTokenValue();
  }


downloadTrainingFile(fileName: string) {
    this.dataServices.preloader = true;
    let filename = '';
    // filename = this.plantSelectedValue + '_PackingSlip';
    this.dataServices.downloadTrainingFile(fileName).subscribe(res => {
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if (isIEOrEdge) {
        const fileURL = URL.createObjectURL(res);
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(res);
          link.download = fileName;
          link.click();
        }
      }
      else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = fileName;
        link.click();
      }
      this.dataServices.preloader = false;
    },
      error => {
        console.log('download error message >>>   ', error);
        this.dataServices.preloader = false;
      });

  }

  getPlantString(code: string, name: string) {
    //console.log("code   ", code);
    //console.log("name   ", name);   

    if (name == null) {
      return code;
    } else {
      return code + " - " + name;
    }    
  }

  showMore(i) {    
    this.arrShowComments[i] = this.arrShowComments[i] == true ? false : true;
  }
  setHeader() {    
  }

  extractComplete: boolean = false;
  
  onUpdate() {
    
    this.loadingMessage = "Please wait while updating the data.";
    this.dataServices.preloader = true;
    var dataToSend = this.data.filter(function (dt) {
      //console.log("dt.editFlag   ", dt.editFlag);
      //console.log("dt.commentsAdded   ", dt.commentsAdded);
      if (dt.editFlag != undefined) {
        return (dt.editFlag == "true");
      }      
    });

    //console.log("dataToSend   ", dataToSend);

    this.dataServices.updateData(dataToSend).subscribe(
      dt => {
        //console.log("message after update >>>   ", dt);
        this.dataServices.preloader = false;
        this.loadingMessage = "";
        this.onSearch();
      },
      error => {
       // console.log("error message >>>   ", error);
        this.dataServices.preloader = false;
        this.loadingMessage = "";
      }
    );
    
  }

  getFilterData() {    
    this.ddTargetLTData = [
      { "value": "TEXIST", "text": "Target Exists" },
      { "value": "TMET", "text": "Target Met" },
      { "value": "TNMET", "text": "Target Not Met" }
    ];
    this.ddDemandData = [
      { "value": "DMDGT0", "text": "Demand > 0" },
      { "value": "DMD0", "text": "No Demand" }
    ];    
    this.ddActionData = [
      { "value": "SUPPLIER", "text": "Supplier Actions" },
      { "value": "BUYER", "text": "SBM Actions" },
      { "value": "BUYEROLD", "text": "SBM Actions - OLD" },
      { "value": "SAP", "text": "Ready for SAP" },
      { "value": "EXTRACTED", "text": "Already Extracted" },
      { "value": "SUPPSAP", "text": "Supplier = SAP" },

    ];    
  }

  getUrlParams(name): string {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
      return null;
    }
    else {
      return decodeURI(results[1]);
    }
  }

  onExtractExcel() {
    this.dataServices.extractLoader = true;

    this.dataServices.getDownloadDataExtractExcel(this.filterModel).subscribe(data => {

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "LeadTimeUpdateExtract_data.xlsx");

      } else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = "LeadTimeUpdateExtract_data.xlsx";
        link.click();
      }

      this.dataServices.preloader = false;
      this.dataServices.extractLoader = false;
      this.extractComplete = true;
      //this.dataServices.preloader = false;
      this.loadingMessage = "";

      this.timeoutID = window.setTimeout(() => {
        this.extractComplete = false;
      }, 3000)

    });

  }

  onDownloadExcel() {
    this.dataServices.getDownloadDataExcel(this.filterModel).subscribe(data => {

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, "LeadTimeUpdate_data.xlsx");
      } else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = "LeadTimeUpdate_data.xlsx";
        link.click();
      }

      this.dataServices.preloader = false;
    });

  }

  
  isBuyerActionSelected(buyerAction): boolean {
    return (buyerAction == 1);
  }

  buyerActionChanged(buyerAction, n) {
    //buyerAction = !buyerAction;
    this.data[n]["buyerAction"] = this.data[n]["buyerAction"] == "0" ? "1" : "0";  

    if (this.data[n].buyerAction != this.originalData[n].buyerAction) {
      this.data[n].editFlag = "true";
    }
    else {
      if (this.data[n].suppLeadTime == this.originalData[n].suppLeadTime &&
        this.data[n].sbmLeadTime == this.originalData[n].sbmLeadTime) {
        if (this.data[n].commentsAdded) {
          if (this.data[n].commentsAdded.length == 0) {
            this.data[n].editFlag = "false";
          }
        } else {
          this.data[n].editFlag = "false";
        }
        
      }
    }

    //console.log("on Changed nnnn    ", n);    
    //console.log("on Changed     ", this.data);
    
  }

  onSearch() {
    this.initialSearchMessage = false;
    this.pageNo = 1;
    this.getData();    
  }

  getData() {
    
    this.dataServices.preloader = true;
    this.loadingMessage = "Please wait while loading the data.";
    this.data = [];
    this.arrShowComments = new Array(50);
    this.filterModel.pageNumber = this.pageNo.toString();
    this.dataServices.getData(this.filterModel).subscribe((data: any) => {
      this.data = data;
      this.data = this.filterComments(this.data);
      
      this.loadingMessage = "";
      //this.originalData = Object.assign({}, data)
      if (this.data.length > 0) {
        this.totalRecords = data[0].totalNoOfParts;
        this.totalPages = Math.ceil(this.totalRecords / this.filterModel.pageSize);
        this.updatePageString();
        this.originalData = JSON.parse(JSON.stringify(this.data))
        //console.log("data   ", data);
      }      
      this.dataServices.preloader = false;      
    });
  }
  filterComments(arr: any) {
    const now = new Date(); 
    const currentYear = now.getFullYear();
    const prevYear = now.getFullYear() - 1;
    
    for (var i = 0; i < arr.length; i++) {
      var newComments="";
      
      if (arr[i]["comments"] != "") {
        
        var splitComments = arr[i]["comments"].replace(/(?:\r\n|\r|\n)/g, '↵');
        splitComments = splitComments.split('↵'); 

        for (var j = 0; j < splitComments.length; j++) {
          var checkDate;
          if (splitComments[j].indexOf("Extracted") != -1) {
            checkDate = splitComments[j].split("-")[1];
          } else {
            checkDate = splitComments[j];
          }
          //console.log("checkDate  ", checkDate);

          if (checkDate.indexOf(currentYear) != -1 ||
            checkDate.indexOf(prevYear) != -1) {
            newComments += splitComments[j] + "\n";
            //console.log("newComments  ", newComments);
          }

        }
      }

      arr[i]["comments"] = newComments;
    }
    //console.log("arr  ", arr);
    return arr;   
  }
  onNext() {
    if (this.pageNo < this.totalPages) {
      this.pageNo++;
      this.getData();
    }
  }

  onPrevious() {
    if (this.pageNo > 1) {
      this.pageNo--;
      this.getData();
    }
  }

  updatePageString() {
    this.pageNumberString = "Display Data " + ((Number(this.filterModel.pageSize) * (this.pageNo - 1)) + 1) + " to " + ((Number(this.filterModel.pageSize) * (this.pageNo - 1)) + this.data.length) + " of " + this.totalRecords;
  }

  supLeadTimeChange(n) {    
    if (this.data[n].suppLeadTime != this.originalData[n].suppLeadTime ||
      this.data[n].sbmLeadTime != this.originalData[n].sbmLeadTime
      ) {
      this.data[n].editFlag = "true";
    } else {
      this.data[n].editFlag = "false";
    }

    if (this.data[n].commentsAdded != undefined) {
      if (this.data[n].commentsAdded.length > 0) {
        this.data[n].editFlag = "true";
      }
    }
    //console.log("supLeadTimeChange  >>   ", this.data[n].suppLeadTime);
    //console.log("original>>     ", this.originalData[n].suppLeadTime);
    //console.log("this.data[n].editFlag  >>   ", this.data);

  }

  selectMaterial(material: any) {
    //this.modelMaterial = material.partNo;
    this.filterModel.partNo = material.code;
    this.materialList = [];
  }

  clickedOutSide() {
    this.materialOut();
  }

  materialOut() {
    this.materialList = [];
    this.materialAutoCompleteStatus = "";
  }

  materialFilter() {
    //console.log("Material model  this.porFilterModel >>>    ", this.filterModel.partNo);
    if (this.filterModel.partNo && this.filterModel.partNo.length > 2) {
      this.materialAutoCompleteStatus = "Loading ... ";
      this.dataServices.getMaterialList(this.filterModel.partNo).subscribe(data => {

        //console.log("material lis:  ", data.length);
        if (data) {
          this.materialList = data;
          this.materialAutoCompleteStatus = data.length == 0 ? "No Records" : "";
        } else {
          //console.log("data false:  ", data.length);
          //console.log("no data ");
          this.materialList = [];
          this.materialAutoCompleteStatus = "No Records";
        }

      });

    } else {
      this.materialList = [];
      this.materialAutoCompleteStatus = "";
    }
  }

  ngOnInit() {
   fromEvent(this.materialCodeElt.nativeElement, 'keyup').pipe(debounceTime(1000)).subscribe(value => this.materialFilter());
   
  }
  onKeyUp(event) {
    //console.log("no data ... ", event);
    if (this.timeoutID) window.clearTimeout(this.timeoutID);
    this.timeoutID = window.setTimeout(() => {
      //console.log("check for debounce ... ");
      this.materialFilter();
    }, 1000)

    
  }
  timeoutID: any;
//  keyup$: Observable<{}>;
  

//this.input$ = this.keyup$.map(ev => (<HTMLInputElement>(<KeyboardEvent>ev).target).value);

//this.inputListener = this.input$.subscribe(text => this.outputValue(text));

  onScroll($event) {
    //console.log("hello ...    ", $event); 
    //console.log("client height ", document.getElementById("tableMain").clientHeight);
    //console.log("scroll height ", document.getElementById("tableMain").scrollHeight);
    document.getElementById("tableHeader").scrollLeft = document.getElementById("tableMain").scrollLeft;
  }

}

